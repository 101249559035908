<script setup lang="ts">
import * as Sentry from '@sentry/nuxt'

const logger = useLogger()

async function handleReportClick() {
    logger.log('Feedback report button clicked')
    useEventCapture('feedback_report_clicked')
    const feedback = Sentry.feedbackIntegration({
      autoInject: false,
      showBranding: false,
    })

    const form = await feedback.createForm()
    form.appendToDom()
    form.open()
}
</script>

<template>
  <Button variant="ghost" size="sm" @click="handleReportClick">
    <Icon name="soilicon:notification-message" class="mr-2 h-4 w-4" />
    Report Issue
  </Button>
</template>
