<script setup lang="ts">
  const colorMode = useColorMode()
</script>

<template>
  <div class="min-h-screen w-full">
    <div class="relative h-full">
      <!-- Background Pattern (Dark Mode) -->
      <PostHogFeatureFlag name="app_background_pattern">
        <div v-if="colorMode.value === 'dark'" class="absolute inset-0 -z-10">
          <div
            class="relative h-full w-full -z-10 bg-slate-950 [&>div]:absolute [&>div]:inset-0 [&>div]:bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] [&>div]:bg-[size:14px_24px] [&>div]:[mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_100%)]"
          >
            <div />
          </div>
        </div>

        <!-- Background Pattern (Light Mode) -->
        <div
          v-if="colorMode.value === 'light'"
          class="absolute inset-0 -z-10 h-full w-full bg-white bg-[linear-gradient(to_right,#8080800a_1px,transparent_1px),linear-gradient(to_bottom,#8080800a_1px,transparent_1px)] bg-[size:14px_24px]"
        >
          <!-- <div class="absolute left-0 right-0 top-0 -z-10 m-auto h-[310px] w-[310px] rounded-full bg-primary opacity-20 blur-[100px]" /> -->
        </div>
      </PostHogFeatureFlag>

      <!-- Page Content -->
      <HeaderNavModern />

      <main class="min-h-[calc(100vh-var(--header-height))]">
        <NuxtPage />
      </main>

      <footer class="bg-background text-muted-foreground py-4 mt-auto text-xs border-t">
        <div class="container mx-auto px-4">
          <div class="flex flex-col md:flex-row justify-between items-center">
            <p class="text-sm mb-2 md:mb-0">
              &copy; {{ new Date().getFullYear() }} Soil Link Ltd. All rights reserved.
            </p>
            <p class="text-xs mb-2 md:mb-0 opacity-80">
              <PostHogFeatureFlag name="app_feedback_button_footer">
                <FeedbackReportButton />
              </PostHogFeatureFlag>
            </p>
            <div class="flex space-x-4">
              <NuxtLink
                to="https://usesoil.link/help" target="_blank"
                class="hover:text-current transition-colors duration-300"
              >
                Help
              </NuxtLink>
              <NuxtLink
                to="https://usesoil.link/terms" target="_blank"
                class="hover:text-current transition-colors duration-300"
              >
                Terms
              </NuxtLink>
              <NuxtLink
                to="https://usesoil.link/privacy" target="_blank"
                class="hover:text-current transition-colors duration-300"
              >
                Privacy
              </NuxtLink>
              <NuxtLink
                to="https://usesoil.link/contact" target="_blank"
                class="hover:text-current transition-colors duration-300"
              >
                Contact
              </NuxtLink>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:flex-row justify-center items-center opacity-20 mt-8">
          <div class="mr-4">
            {{ useRuntimeConfig().public.environment }} / v{{
              useRuntimeConfig().public.release
            }}
          </div>
          <div class="flex gap-2">
            <ErrorTestButton />
          </div>
        </div>
      </footer>
    </div>
    <BotLoader />
  </div>
</template>
